import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PurchaseSummary from './PurchaseSummary';

const Success = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const session_id = new URLSearchParams(location.search).get('session_id');
  console.log('ID de sesión:', session_id);
  const hasFetched = useRef(false);
  const [buyerDetails, setBuyerDetails] = useState(null);
  const [saleId, setSaleId] = useState(null);
  const [status, setStatus] = useState('checking');

  // const fetchSaleDetails = async (saleId) => {
  //   try {
  //     const response = await fetch(`${process.env.REACT_APP_API_URL}/api/sales/stripe/${saleId}`);
  //     if (!response.ok) throw new Error('Error al obtener los detalles de la venta');
  //     const data = await response.json();
  //     return data;
  //   } catch (error) {
  //     console.error('Error al obtener los detalles de la venta:', error);
  //     return null;
  //   }
  // };

  // useEffect(() => {
  //   const fetchSessionData = async () => {
  //     try {
  //       if (!session_id || hasFetched.current) return;
  //       hasFetched.current = true;

  //       const stripeResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/payment/session/${session_id}`);
  //       const data = await stripeResponse.json();
  //       console.log('DATA STRIPE RESPONSE########')
  //       console.log(data)
  //       if (!data.success) {
  //         setStatus('pending');
  //         setTimeout(() => {
  //           hasFetched.current = false;
  //         }, 2000);
  //         return;
  //       }

  //       const { session } = data;
        
  //       const sale = await fetchSaleDetails(session_id);
  //       if (sale) {
  //         setSaleId(sale.sale_id);
  //         setStatus('confirmed');
  //         return;
  //       }

  //       setBuyerDetails({
  //         buyer_name: session.customer_details.name,
  //         buyer_email: session.customer_details.email,
  //         stripe_payment_id: session_id
  //       });
  
  //       const purchaseData = JSON.parse(localStorage.getItem('purchaseData') || '{}');
  
  //       const saleData = {
  //         date: purchaseData.event_date,
  //         time: purchaseData.event_time,
  //         type: purchaseData.ticket_type,
  //         quantity: parseInt(purchaseData.quantity),
  //         buyer_info: {
  //           name: session.customer_details.name,
  //           email: session.customer_details.email
  //         },
  //         stripe_payment_id: session_id,
  //         total_amount: parseFloat(purchaseData.total_amount)
  //       };
  
  //       const saleResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/sales`, {
  //         method: 'POST',
  //         headers: { 'Content-Type': 'application/json' },
  //         body: JSON.stringify(saleData)
  //       });
  
  //       if (!saleResponse.ok) {
  //         const saleError = await saleResponse.json();
  //         throw new Error(saleError.message || 'Error al registrar la venta.');
  //       }
  
  //       console.log('Venta creada exitosamente.');
  
  //       const saleIdResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/sales/stripe/${session_id}`);
  //       if (!saleIdResponse.ok) {
  //         throw new Error('Error al obtener el sale_id.');
  //       }
  
  //       const saleIdData = await saleIdResponse.json();
  //       setSaleId(saleIdData.sale_id);
  //       console.log('Sale ID encontrado:', saleIdData.sale_id);
  
  //       const updateTicketsData = {
  //         event_date: purchaseData.event_date,
  //         event_time: purchaseData.event_time,
  //         ticket_type: purchaseData.ticket_type.toLowerCase(),
  //         quantity: parseInt(purchaseData.quantity)
  //       };
  
  //       const updateTicketsResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/events/update-tickets`, {
  //         method: 'PUT',
  //         headers: { 'Content-Type': 'application/json' },
  //         body: JSON.stringify(updateTicketsData)
  //       });
  
  //       if (!updateTicketsResponse.ok) {
  //         const updateError = await updateTicketsResponse.json();
  //         throw new Error(updateError.message || 'Error al actualizar los boletos.');
  //       }
  
  //       console.log('Venta registrada y boletos actualizados correctamente.');
  //       localStorage.removeItem('purchaseData');

  //       setStatus('confirmed');
  //     } catch (error) {
  //       console.error('Error al procesar los datos:', error);
  //       setStatus('error');
  //     }
  //   };

  //   fetchSessionData();
  // }, [session_id]);

  // if (status === 'checking') {
  //   return (
  //     <div className="success-container">
  //       <div className="success-card">
  //         <h2>Verificando tu pago...</h2>
  //         <div className="spinner-border text-primary" role="status">
  //           <span className="visually-hidden">Cargando...</span>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }

  // if (status === 'pending') {
  //   return (
  //     <div className="success-container">
  //       <div className="success-card">
  //         <h2>Procesando tu pago...</h2>
  //         <div className="spinner-border text-primary" role="status">
  //           <span className="visually-hidden">Cargando...</span>
  //         </div>
  //         <p>Por favor, espera mientras confirmamos tu transacción.</p>
  //       </div>
  //     </div>
  //   );
  // }

  // if (status === 'error') {
  //   return (
  //     <div className="success-container">
  //       <div className="success-card">
  //         <h2>Error al procesar el pago</h2>
  //         <p>Hubo un problema al verificar tu pago. Por favor, contacta a soporte.</p>
  //         <button onClick={() => navigate('/')} className="buy-button">
  //           Volver al inicio
  //         </button>
  //       </div>
  //     </div>
  //   );
  // }

//   return (
//     <div className="success-container">
//       <div className="success-card">
//         <h1 className="success-title">¡Pago Exitoso!</h1>
//         <div className="mb-4">
//           <i className="bi bi-check-circle-fill success-icon"></i>
//         </div>
//         <p className="success-message">¡Gracias por comprar tus boletos!</p>
//         <p className="success-message">Tu compra ha sido procesada exitosamente.</p>

//         {saleId && <PurchaseSummary saleId={saleId} />}

//         <div className="d-grid gap-3 col-md-8 mx-auto">
//           <button
//             onClick={() => navigate('/')}
//             className="buy-button"
//           >
//             Volver al inicio
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// NUEVO
return (
  <div className="success-container">
      <div className="success-card">
          <h2>Compra procesada</h2>
          <p>Comprueba tu email con el resumen de tu compra. Puede tardar unos minutos en procesarse.</p>
        <button onClick={() => navigate('/')} className="buy-button">
          Volver al inicio
        </button>
    </div>
  </div>
)}

export default Success;
