import React, { useState } from 'react';
import TicketSelection from './TicketSelection';
import CheckoutForm from './CheckoutForm';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

//const stripePromise = loadStripe('pk_live_51QUJ8kATpSfJ7e1LfD7n8PoOcJkzf3c6DCkeoYms9epcplDF5FBOpw3HCpCEXfA7XFsZaudtss9nWcB3scNjEggE00qUBdUIKA');
const stripePromise = loadStripe('pk_test_51QUJ8kATpSfJ7e1LE8DOxzEOqCpLTRZlMkQ5QRgfrbGOylokWI9tRwwv0gF1ude6bMn35gpttqAiEFAcYvRiI76600dwbWaPS6');
//const stripePromise = loadStripe('pk_test_51Q7VRJP4l78aOUuh3pP1Y5RGn6BEDGrUvob8mGA5RlyiR6HwZkgfipdiyOPcE3rLa3JYqxhI91jhTa9EIdZPrLp8000jARhDDJ'); anyelifugaz@fugaz.com

const PurchasePage = () => {
  
  return (
    <div className="purchase-container">
        <TicketSelection/>
    </div>
  );
};

export default PurchasePage;
