import { useEffect, useState } from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const PurchaseSummary = ({ saleId }) => {
  const [saleDetails, setSaleDetails] = useState(null);
  const [qrCode, setQrCode] = useState(null);

  useEffect(() => {
    const fetchSaleDetails = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/sales/${saleId}`);
        if (!response.ok) throw new Error('Error al obtener los detalles de la venta');
        const data = await response.json();
        setSaleDetails(data);
      } catch (error) {
        console.error('Error al obtener los detalles de la venta:', error);
      }
    };

    if (saleId) fetchSaleDetails();
  }, [saleId]);

  useEffect(() => {
    const generateQrCode = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/qr`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ sale_id: saleId })
        });
        if (!response.ok) throw new Error('Error al generar el código QR');
        const data = await response.json();
        setQrCode(data.qr_code);
      } catch (error) {
        console.error('Error al generar el código QR:', error);
      }
    };

    if (saleId) generateQrCode();
  }, [saleId]);

  const generatePDF = () => {
    const summaryElement = document.getElementById('purchase-summary');
    if (!summaryElement) {
      console.error('No se encontró el elemento para generar el PDF.');
      return;
    }

    html2canvas(summaryElement, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = pageWidth;
      const imgHeight = (canvas.height * pageWidth) / canvas.width;

      pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
      pdf.save('resumen_compra.pdf');
    }).catch((error) => {
      console.error('Error al generar el PDF:', error);
    });
  };

  if (!saleDetails) {
    return <p>Cargando detalles de la venta...</p>;
  }

  return (
    <div>
      <div id="purchase-summary">
        <h2>Resumen de la Venta</h2>
        <p><strong>Fecha del Evento:</strong> {saleDetails.event_date}</p>
        <p><strong>Hora del Evento:</strong> {saleDetails.event_time}</p>
        <p><strong>Tipo de Boleto:</strong> {saleDetails.ticket_type}</p>
        <p><strong>Cantidad:</strong> {saleDetails.quantity}</p>
        <p><strong>Total Pagado:</strong> ${saleDetails.total_amount}</p>
        <p><strong>Nombre del Comprador:</strong> {saleDetails.buyer_info.name}</p>
        <p><strong>Email del Comprador:</strong> {saleDetails.buyer_info.email}</p>

        {qrCode && (
          <div className="mt-4 text-center">
            <h3>Código QR</h3>
            <img src={qrCode} alt="Código QR" />
          </div>
        )}
      </div>

      <div className="button-container text-center">
        <button onClick={generatePDF} className="btn-secondary">
          Descargar PDF
        </button>
      </div>
    </div>
  );
};

export default PurchaseSummary;
